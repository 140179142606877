<template>
    <div>
        <div class="padding100">
			<side-bar-admin/>
			<div class="creation-container" >
				<div class="div-creation">
					<h4 class="title-card">Création d'une table</h4>
					<v-col >
						<v-select v-model="type_tag_selected" :items="typeTag" label="Choix du type de matériel" chips persistent-hint></v-select>
					</v-col>
					<v-col >
						<v-text-field
							v-model="idTable"
							:label="`id`"
							:prefix="getPrefix(type_tag_selected)"
							@input="handleInput"
							@paste="handlePaste"
						></v-text-field>
					</v-col>
					<v-col style="text-align: center; ">
						<button text v-if="showCreation1 " @click.prevent="validationTable()" class="button-admin">Valider la création</button>
					</v-col>
				</div>
				<div class="div-creation">
						<h4 class="title-card">Attribution d'un matériel</h4>
						<v-col >
							<v-select v-model="tag_list_selected" :items="typeTag" label="Choix du type de matériel" chips persistent-hint @change="selectList"></v-select>
						</v-col>
						<v-col >
							<v-select v-model="tag_selected" :items="listGenerated" label="Choix du tag" chips persistent-hint @change="GetUsers"></v-select>
						</v-col>
						<v-col >
							<v-select v-model="user_selected" :items="listUsers" label="Choix de l'utilisateur" chips persistent-hint @change="showValidation2 = true"></v-select>
						</v-col>
						<v-col>
							<button text v-if="showValidation2 == true" @click.prevent="validationAttribution()" class="button-admin" >Valider l'attribution</button>
						</v-col>
					</div>
			</div>
				<v-dialog v-model="dialog" max-width="290">
					<v-card>
						<v-card-title class="headline">Confirmation</v-card-title>
						<v-card-text>
							Êtes-vous sûr de vouloir créer une nouvelle table {{ tag_id }} ?
						</v-card-text>
						<v-card-actions>
							<v-btn color="blue darken-1" text @click="dialog = false">Non</v-btn>
							<v-btn color="blue darken-1" text @click="confirmValidation">Oui</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
        </div>
    </div>
</template>

<script>
import SideBarAdmin from '../components/SideBarAdmin.vue';
    export default {
  components: { SideBarAdmin },
        data () {
            return {
                typeTag: ['implant'],
                prefix: ['bts_'],
                type_tag_selected: '',
				tag_list_selected: '',
				tag_id: '',
				tagIdLength: null,
				listGenerated : [],
				listUsers : [],
				listEmail: [],
				listTag : [],
				user_selected : '',
				tag_selected: '',
                idTable: '',
				showCreation1 : false,
				showValidation1 : false,
				showValidation2 : false,
				dialog: false,
				errors: [],
            }
        },

        methods:{
			//Creation
            getPrefix(tag) {
                const index = this.typeTag.indexOf(tag);
                return this.prefix[index] || '';
            },
			validationTable() {
				this.tag_id = this.getPrefix(this.type_tag_selected) + this.idTable;
				this.dialog = true; // Affiche la fenêtre modale
			},
			handleInput(value) {
				if (value.length === 5) {
					this.showCreation1 = true;
				}
			},
			handlePaste(event) {
				console.log('hehehey');
				setTimeout(() => {
					if (this.idTable && this.idTable.length >= 5) {
						this.showCreation1 = true;
					}
				}, 50); // Ajoutez un délai de 50ms
			},

            confirmValidation(){
                this.dialog = false;
				if(!this.idTable ){
					this.errors.push("please enter all fields")
				}
				if(this.errors.length > 0){
					window.alert(this.errors)	
				}else{
					this.checkTagExistence();
				}
				this.errors = [];
            },
			async checkTagExistence(){
				const checked = await Promise.all([this.selectTagExistence()])
				if(checked){
					if(this.tagIdLength > 0){
						window.alert('le tag id existe déjà dans la base de donnée')
						return false;
					}else{ 
						await this.CreateTagTables();
						await this.postTag();
						this.idTable = '';
						this.type_tag_selected = '';
						this.tag_list_selected = '';
						this.tag_selected = '';
						this.user_selected = '';
						await window.alert('création des tables dans la base de donnée effectuée')
					}
				}else{
					return
				}
			},
			//2.1- Appelle de la requête qui récupère le tag_id
			async selectTagExistence(){
				const tag_id = encodeURIComponent(this.tag_id)
				const url = this.$api.getRESTApiUri() + `/admin/get-tag/${tag_id}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					console.log(data)
					this.tagIdLength = data.length
					console.log(this.tagIdLength);
				})
				.catch((error) => {
					console.log(error)
				});	
			},
			//3- On crée le nouveau tag dans la table de donnée tag_manager
			async postTag(){
				const url = this.$api.getRESTApiUri() + `/admin/tag_creation`
				fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							tag_id: this.tag_id,
						}),
				})
			.then(response => response.json())
			.then(json => console.log(json));		
			},
			//4- On crée les tables hub et max30101
			async CreateTagTables(){
				let url;
				url = this.$api.getRESTApiUri() + `/admin/table_creation`;
				console.log(url)
				fetch(url, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
                        body: JSON.stringify({
							tag_id : this.tag_id,
						}),
				})
			// Converting to JSON
			.then(response => response.json())
			// Displaying results to console
			.then(json => console.log(json));		
			},
			
			// Attribution 
			async selectList(){
				this.listGenerated = []
				console.log(this.tag_list_selected)	
				if(this.tag_list_selected == 'implant'){
					this.GetTags();
				}
			},
			async GetTags(){
				const url = this.$api.getRESTApiUri() + `/get-tag-id`;
				return fetch(url, {
						method: 'GET',
						headers: {
							'Content-Type': 'application/json',
						},
				})
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result)
					console.log(data)
					this.sortTagsName(data);
					console.log(this.listGenerated)
				})
				.catch((error) => {
					console.log(error)
				});
			},
			async GetUsers() {
				const url = this.$api.getRESTApiUri() + `/get-users`;
				console.log(url);
				return fetch(url)
				.then((res) => res.text())
				.then((result) => {
					const data = JSON.parse(result);
					for(let i = 0; i < data.length; i++){
						this.listUsers.push(data[i].email)
					}
					console.log(this.listUSers)
					})
					.catch((error) => {
					console.log(error);
					});
			},
			async validationAttribution(){
				console.log('c\'est bon')
				const checkedEmail = await Promise.all([this.selectEmailAttribution()]);
				const checkedTag = await Promise.all([this.selectTagAttribution()]);
				if(checkedEmail && checkedTag){
					if(this.listEmail == null){
						window.alert('mail attribué')
						 this.InsertMailTagManager();
					}else{
						if(this.listEmail.includes(this.user_selected)){
							window.alert('l\'email: '+ this.user_selected + ' est déjà attribué')
						}else{
							await this.InsertMailTagManager();
							window.alert('mail attribué');
						}
					}
					if(this.listTag == null){
						await this.InsertTagUserAccount();
						window.alert('tag attribué')
						
					}else{
						if(this.listTag.includes(this.tag_selected)){
							window.alert('le tag est déjà attribué')
						}else{
							await this.InsertTagUserAccount();
							window.alert('tag attribué')
							
						}
					}
				}else{
					return
				}
            },
			//3.1- Check de l'attribution d'un utilisateur à son tag 
			async selectEmailAttribution(){
				const tag_id = encodeURIComponent(this.tag_selected)
				console.log(tag_id)
				const url = this.$api.getRESTApiUri() + `/admin/get-email-attribution/${tag_id}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					console.log(data[0].email)
					this.listEmail = data[0].email;
					console.log(this.listEmail)
				})
				.catch((error) => {
					console.log(error)
				});
			},
			//3.1- Check de l'attribution d'un tag à son utilisateur
			async selectTagAttribution(){
				const email = encodeURIComponent(this.user_selected)
				const url = this.$api.getRESTApiUri() + `/admin/get-tag-attribution/${email}`
				return fetch(url)
                .then(res => res.text())
                .then((result) => {
                    const data = JSON.parse(result);
					this.listTag = data[0].tag_attributed;
					console.log(this.listTag)
				})
				.catch((error) => {
					console.log(error)
				});
			},
			//4.1- Attribution du tag au user (tag -> user_account)
			async InsertTagUserAccount(){
				console.log('heyy')
				const url = this.$api.getRESTApiUri() + `/admin/tag-attribution-to-user`;
				fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
							email: this.user_selected,
							tag_id: this.tag_selected,
						}),
				})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json));		
			},
			//4.2- Attribution du user au tag (user -> tag_manager)
			async InsertMailTagManager(){
				const url = this.$api.getRESTApiUri() + `/admin/mail-attribution-to-tag`;
				console.log(this.tag_list_selected)
				fetch(url, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email: this.user_selected,
						tag_id: this.tag_selected,
					}),
				})
				// Converting to JSON
				.then(response => response.json())
				// Displaying results to console
				.then(json => console.log(json));		
			},
			sortTagsName(liste_tag_name){
				const sortedTags = liste_tag_name.map(item => item.tag_id).sort((a, b) => {
            // Si a est un nombre et b une lettre, a vient en premier
					if (!isNaN(a[0]) && isNaN(b[0])) return -1;
					// Si a est une lettre et b un nombre, b vient en premier
					if (isNaN(a[0]) && !isNaN(b[0])) return 1;
					// Sinon, comparez normalement
					return a.localeCompare(b);
				});
				this.listGenerated = sortedTags
			}
			
        }
    }
</script>

<style scoped>
.creation-container{
	display: flex;
	justify-content: space-around;
	margin: 150px 0px;
}
.div-creation{
	background-color: white;
	border-radius: 20px;
	padding: 20px;
	width: 20%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.title-card{
	font-family: Roboto;
    font-size: 1.5rem;
    color: var(--vertfonce);
	margin-bottom: 20px;
	text-align: center;
}
.button-admin{
	background-color: var(--vertfonce);
	color: white;
	border-radius: 15px;
	padding: 10px 20px;
	font-family: 'Roboto';
	border: solid 2px;
}
.button-admin:hover{
	background-color: transparent;
	color: var(--vertfonce);
	transition: 0.5s;
}
</style>